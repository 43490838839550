import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer-container.css'

const FooterContainer = (props) => {
  return (
    <div
      className={`footer-container-footer-container ${props.rootClassName} `}
    >
      <div className="footer-container-footer">
        <div className="footer-container-social-links">
          <a
            href="https://daiki-bot.xyz/support"
            target="_blank"
            rel="noreferrer noopener"
            className="footer-container-link"
          >
            <img
              alt={props.image_alt}
              src={props.image_src}
              className="footer-container-image"
            />
          </a>
        </div>
        <div className="footer-container-social-links1">
          <Link to="/privacy" className="footer-container-navlink">
            {props.privicy}
          </Link>
          <Link to="/tos" className="footer-container-navlink1">
            {props.TOS}
          </Link>
        </div>
        <div className="footer-container-mobiloe-social-links">
          <Link to="/privacy" className="footer-container-navlink2">
            {props.privicyM}
          </Link>
          <Link to="/tos" className="footer-container-navlink3">
            {props.TOSM}
          </Link>
        </div>
        <div className="footer-container-copyright-container">
          <a
            href="https://www.copyrighted.com/website/OjYDIEkFxuoxr53E"
            target="_blank"
            rel="noreferrer noopener"
            className="footer-container-link"
          >
            <svg viewBox="0 0 1024 1024" className="footer-container-icon">
              <path
                d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM506 390q-80 0-80 116v12q0 116 80 116 30 0 50-17t20-43h76q0 50-44 88-42 36-102 36-80 0-122-48t-42-132v-12q0-82 40-128 48-54 124-54 66 0 104 38 42 42 42 98h-76q0-14-6-26-10-20-14-24-20-20-50-20z"
                className=""
              ></path>
            </svg>
          </a>
          <span className="footer-container-text">
            {props.mobile_copyright}
          </span>
          <span className="footer-container-text1">{props.copyright}</span>
        </div>
      </div>
    </div>
  )
}

FooterContainer.defaultProps = {
  privicyM: 'Privacy',
  mobile_copyright: 'Daiki Development',
  image_alt: 'image',
  rootClassName: '',
  TOS: 'Terms of Service',
  privicy: 'Privacy Policy',
  image_src: '/playground_assets/discord%20logo.svg',
  TOSM: 'TOS',
  copyright: 'All Rights Reserved, Daiki Development',
}

FooterContainer.propTypes = {
  privicyM: PropTypes.string,
  mobile_copyright: PropTypes.string,
  image_alt: PropTypes.string,
  rootClassName: PropTypes.string,
  TOS: PropTypes.string,
  privicy: PropTypes.string,
  image_src: PropTypes.string,
  TOSM: PropTypes.string,
  copyright: PropTypes.string,
}

export default FooterContainer
