import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './contact.css'

const Contact = (props) => {
  return (
    <html>
      <head>
        <title>Contact - Daiki Development</title>
        <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes='32x32' />
        <meta property='og:title' content="Contact - Daiki Development" />
        <meta
          property="og:description"
          content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
        />
        <meta property='og:url' content="https://daiki-bot.xyz/" />
        <meta property='og:image' content='/playground_assets/copy_of_2022 [1].png' />
      </head>
      <div className="contact-container">
        <Helmet>
          <title>Contact - Daiki Development</title>
          <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes="32x32" />
          <meta property="og:title" content="Contact - Daiki Development" />
          <meta
            property="og:description"
            content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
          />
        </Helmet>
        <NavbarContainer rootClassName="navbar-container-root-class-name"></NavbarContainer>
        <div className="contact-contact-info">
          <div className="contact-separator"></div>
          <div className="contact-container1">
            <div className="contact-container2">
              <div className="contact-feature-card">
                <svg viewBox="0 0 1024 1024" className="contact-icon">
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
                <h2 className="contact-text">Email</h2>
                <a
                  href="mailto:support@daiki-bot.yxz?subject="
                  className="contact-link"
                >
                  <span>support@daiki-bot.yxz</span>
                  <br></br>
                </a>
              </div>
              <div className="contact-feature-card1">
                <svg viewBox="0 0 1024 1024" className="contact-icon02">
                  <path d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"></path>
                </svg>
                <h2 className="contact-text03">
                  <span className="contact-text04">Phone</span>
                  <br></br>
                </h2>
                <a href="tel:+12675449565" className="contact-link1">
                  +1 (267) 544-9565
                </a>
              </div>
              <div className="contact-feature-card2">
                <img
                  alt="image"
                  src="/playground_assets/discord%20logo.svg"
                  className="contact-image"
                />
                <h2 className="contact-text06">
                  <span className="contact-text07">Discord</span>
                  <br></br>
                </h2>
                <a
                  href="https://discord.gg/nWUB8RNB72"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="contact-link2"
                >
                  <span>discord.gg/nWUB8RNB72</span>
                  <br></br>
                </a>
              </div>
              <div className="contact-feature-card3">
                <img
                  alt="image"
                  src="/playground_assets/tiktok-200h.png"
                  className="contact-image1"
                />
                <h2 className="contact-text11">
                  <span className="contact-text12">TikTok</span>
                  <br></br>
                </h2>
                <a
                  href="https://www.tiktok.com/@daikidevelopment"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="contact-link3"
                >
                  <span>@daikidevelopment</span>
                  <br></br>
                </a>
              </div>
            </div>
          </div>
        </div>
        <FooterContainer rootClassName="footer-container-root-class-name2"></FooterContainer>
        <footer className="contact-footer">
          <div className="contact-container3">
            <div className="contact-logo"></div>
            <div className="contact-links-container">
              <div className="contact-container4">
                <div className="contact-product-container">
                  <span className="contact-text16">Product</span>
                  <span className="contact-text17">Features</span>
                  <span className="contact-text18">Pricing</span>
                  <span className="contact-text19">Tutorials</span>
                  <span>Releases</span>
                </div>
                <div className="contact-company-container">
                  <span className="contact-text21">Company</span>
                  <span className="contact-text22">About</span>
                  <span className="contact-text23">Careers</span>
                  <span className="contact-text24">Contact</span>
                  <span>Blog</span>
                </div>
              </div>
              <div className="contact-container5">
                <div className="contact-socials">
                  <span className="contact-text31">Follow Us</span>
                  <div className="contact-icon-group">
                    <svg
                      viewBox="0 0 950.8571428571428 1024"
                      className="contact-icon04"
                    >
                      <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="contact-icon06"
                    >
                      <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 602.2582857142856 1024"
                      className="contact-icon08"
                    >
                      <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-separator1"></div>
          <span className="contact-text32">© 2021 , All Rights Reserved.</span>
        </footer>
      </div>
    </html>
  )
}

export default Contact
