import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './not-found.css'

const NotFound = (props) => {
  return (
    <html>
      <head>
        <title>404 Not Found - Daiki Development</title>
        <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes='32x32' />
        <meta property='og:title' content="404 Not Found - Daiki Development" />
        <meta
          property="og:description"
          content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
        />
        <meta property='og:url' content="https://daiki-bot.xyz/" />
        <meta property='og:image' content='/playground_assets/copy_of_2022 [1].png' />
      </head>
      <div className="not-found-container">
        <Helmet>
          <title>Not Found - Daiki Development</title>
          <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes="32x32" />
          <meta property="og:title" content="Not-Found - Daiki Development" />
          <meta
            property="og:description"
            content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
          />
        </Helmet>
        <NavbarContainer rootClassName="navbar-container-root-class-name3"></NavbarContainer>
        <div className="not-found-container1">
          <img
            alt="image"
            src="/playground_assets/main%20w%2F%20text-200h.png"
            className="not-found-image"
          />
          <span className="not-found-text">
            <span>Sorry Daiki Couldn&apos;t find this page.</span>
            <br></br>
            <span>Maybe try another one?</span>
          </span>
        </div>
        <FooterContainer rootClassName="footer-container-root-class-name1"></FooterContainer>
      </div>
    </html>
  )
}

export default NotFound
