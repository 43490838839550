import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navbar-container.css'

const NavbarContainer = (props) => {
  return (
    <div
      data-role="Header"
      className={`navbar-container-navbar-container ${props.rootClassName} `}
    >
      <div className="navbar-container-navbar">
        <div className="navbar-container-left-side">
          <Link to="/" className="navbar-container-navlink">
            <img
              alt={props.image_alt}
              src={props.image_src}
              className="navbar-container-image"
            />
          </Link>
          <div
            data-thq="thq-dropdown"
            className="navbar-container-thq-dropdown list-item"
          >
            <div
              data-thq="thq-dropdown-toggle"
              className="navbar-container-dropdown-toggle"
            >
              <div
                data-thq="thq-dropdown-arrow"
                className="navbar-container-dropdown-arrow"
              >
                <svg viewBox="0 0 1024 1024" className="navbar-container-icon">
                  <path d="M426 726v-428l214 214z" className=""></path>
                </svg>
              </div>
            </div>
            <ul
              data-thq="thq-dropdown-list"
              className="navbar-container-dropdown-list"
            >
              <li
                data-thq="thq-dropdown"
                className="navbar-container-dropdown list-item"
              >
                <Link to="/" className="">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-container-dropdown-toggle1"
                  >
                    <span className="navbar-container-text">
                      <span className="navbar-container-text01">Home</span>
                      <br className=""></br>
                    </span>
                  </div>
                </Link>
              </li>
              <li
                data-thq="thq-dropdown"
                className="navbar-container-dropdown1 list-item"
              >
                <Link to="/docs" className="">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-container-dropdown-toggle2"
                  >
                    <span className="navbar-container-text03">
                      <span className="navbar-container-text04">Docs</span>
                      <br className=""></br>
                    </span>
                  </div>
                </Link>
              </li>
              <li
                data-thq="thq-dropdown"
                className="navbar-container-dropdown2 list-item"
              >
                <Link to="/contact" className="">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-container-dropdown-toggle3"
                  >
                    <span className="navbar-container-text06">
                      <span className="navbar-container-text07">Contact</span>
                      <br className=""></br>
                    </span>
                  </div>
                </Link>
              </li>
              {/* <li
                data-thq="thq-dropdown"
                className="navbar-container-dropdown3 list-item"
              >
                {<Link to="/status" className="">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-container-dropdown-toggle4"
                  >
                    <span className="navbar-container-text09">Status</span>
                  </div>
                </Link>}
              </li> */}
              <li
                data-thq="thq-dropdown"
                className="navbar-container-dropdown4 list-item"
              >
                <Link to="/commands" className="">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-container-dropdown-toggle5"
                  >
                    <span className="navbar-container-text10">
                      <span className="navbar-container-text11">Commands</span>
                      <br className=""></br>
                    </span>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div className="navbar-container-links-container">
            <Link to="/" className="navbar-container-link Anchor">
              {props.Link}
            </Link>
            <Link to="/docs" className="navbar-container-link1 Anchor">
              {props.Link1}
            </Link>
            <Link to="/contact" className="navbar-container-link2 Anchor">
              {props.Link2}
            </Link>{/* 
            <Link to="/status" className="navbar-container-link3 Anchor">
              {props.Link22}
            </Link> */}
            <Link to="/commands" className="navbar-container-link4 Anchor">
              {props.Link21}
            </Link>
            <a href="#ourstory" className="navbar-container-link5 Anchor">
              {props.Link3}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

NavbarContainer.defaultProps = {
  rootClassName: '',
  image_alt: 'image',
  Link22: 'Status',
  Link2: 'Contact',
  Link21: 'Commands',
  image_src: '/playground_assets/main%20logo-200h.png',
  Link: 'Home',
  Link3: 'Dashboard',
  Link1: 'Docs',
}

NavbarContainer.propTypes = {
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
  Link22: PropTypes.string,
  Link2: PropTypes.string,
  Link21: PropTypes.string,
  image_src: PropTypes.string,
  Link: PropTypes.string,
  Link3: PropTypes.string,
  Link1: PropTypes.string,
}

export default NavbarContainer
