import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './home.css'

const Home = (props) => {
  return (
    <html>
      <head>
        <title>Daiki Development</title>
        <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes='32x32' />
        <meta property='og:title' content="Daiki Development" />
        <meta
          property="og:description"
          content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
        />
        <meta property='og:url' content="https://daiki-bot.xyz/" />
        <meta property='og:image' content='/playground_assets/copy_of_2022 [1].png' />
      </head>
      <div className="home-container">
        <Helmet>
          <title>Daiki Development</title>
          <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes="32x32" />
          <meta property="og:title" content="Daiki Development" />
          <meta
            property="og:description"
            content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
          />
        </Helmet>
        <NavbarContainer rootClassName="navbar-container-root-class-name1"></NavbarContainer>
        {/* <div className="home-notification">
        <h1 className="home-home-notification-heading">New Daiki Commands</h1>
        <span className="home-home-notification-text">
          <span>Want to see our upcoming commands?</span>
          <br></br>
          <span>Hit the button below!</span>
          <br></br>
        </span>
        <a
          href="https://daiki-bot.xyz/commands#new"
          className="home-home-notification-button button"
        >
          Upcoming Commands
        </a>
      </div> */}
        {/* <div className="home-notification">
          <h1 className="home-home-notification-heading">New Daiki Commands</h1>
          <span className="home-home-notification-text">
            <span>Daiki V14.7 Update Information Coming Soon!</span>
            <br></br>
            <span>More Updates Coming Soon!</span>
          <br></br>
          </span>
          <a
          href="https://daiki-bot.xyz/commands#new"
          className="home-home-notification-button button"
        >
          Upcoming Commands
        </a>
        </div> */}
        <div className="home-home-container">
          <div className="home-home-content-container">
            <div className="home-home-text-container">
              <h1 className="home-home-text-heading Section-Heading">
                A Little About Daiki
              </h1>
              <span className="home-home-text">
                <span>
                  Daiki Development is a small group of people wanting to make
                  Discord better, with Discord bots such as Daiki, Daiki Beta, and
                  Daiki Toons. Daiki Development started on May 4th, 2021, our
                  first bot that was launched was called Daiki. This bot
                  couldn&apos;t do so much, all it could really do is say ping,
                  but as the main developer
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  BigTDM 10#1362 began to teach himself to code Daiki got bigger
                  and bigger everyday.
                </span>
              </span>
            </div>
          </div>
        </div>
        <FooterContainer></FooterContainer>
      </div>
    </html>
  )
}

export default Home
