import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './status.css'

class Status extends Component {
  state = {
    MainData: null,
    BetaData: null,
    ToonsData: null,
    ClosedData: null
  };

  componentDidMount() {
    this.fetchData();
    setInterval(() => {
      this.fetchData();
    }, 2555); // request every 2.555 seconds
  }

  fetchData = () => {
    axios.get('https://api.daiki-bot.xyz/bot/839287174482362438')
      .then(response => {
        this.setState({ MainData: response.data });
      });
    axios.get('https://api.daiki-bot.xyz/bot/889334761796010014')
      .then(response => {
        this.setState({ BetaData: response.data });
      });
    axios.get('https://api.daiki-bot.xyz/bot/906675069760200724')
      .then(response => {
        this.setState({ ToonsData: response.data })
      })
    axios.get('https://api.daiki-bot.xyz/bot/981828045717504001')
      .then(response => {
        this.setState({ ClosedData: response.data })
      })
  }

  render() {
    return (
      <html>
        <head>
          <title>Status - Daiki Development</title>
          <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes='32x32' />
          <meta property='og:title' content="Status - Daiki Development" />
          <meta
            property="og:description"
            content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
          />
          <meta property='og:url' content="https://daiki-bot.xyz/" />
          <meta property='og:image' content='/playground_assets/copy_of_2022 [1].png' />
        </head>
        <div className="status-container">
          <NavbarContainer rootClassName="navbar-container-root-class-name7"></NavbarContainer>
          <h1 className="status-last-updated-text">
            <span>Last Updated: {this.state.MainData && <span>{this.state.MainData.date}</span>}</span>
            <br></br>
          </h1>
          <div className="status-mobile-status-container">
            <div className="status-mobile-daiki-status">
              {this.state.MainData && (
                <img
                  style={{ boxShadow: `0px 0px 10px 5px ${this.state.MainData.hex}` }}
                  alt="image"
                  src="https://cdn.discordapp.com/avatars/839287174482362438/cac55e100687c417cdddd25849a548af.png"
                  className="status-mobile-daiki-status-image"
                />
              )}
              <div className="status-m-daiki-status-container">
                <span className="status-m-daiki-status-contain-text">
                  {this.state.MainData && <span>{this.state.MainData.status}</span>}
                  <br></br>
                </span>
                <h1 className="status-m-daiki-status-contain-heading">Daiki</h1>
              </div>
            </div>
            <div className="status-mobile-daiki-beta-status">
              {this.state.BetaData && (
                <img
                  style={{ boxShadow: `0px 0px 10px 5px ${this.state.BetaData.hex}` }}
                  alt="image"
                  src="https://cdn.discordapp.com/avatars/889334761796010014/5419f03a312d8d44344452de8c672055.png"
                  className="status-m-b-status-image"
                />
              )}
              <div className="status-m-b-status-container">
                <span className="status-m-b-status-container-text">
                  {this.state.BetaData && <span>{this.state.BetaData.status}</span>}
                  <br></br>
                </span>
                <h1 className="status-m-b-status-container-heading">Daiki Beta</h1>
              </div>
            </div>
            <div className="status-mobile-daiki-toons-status">
              {this.state.ToonsData && (
                <img
                  style={{ boxShadow: `0px 0px 10px 5px ${this.state.ToonsData.hex}` }}
                  alt="image"
                  src="https://cdn.discordapp.com/avatars/906675069760200724/549fe0c1a6666b7ed19ef4d5c2dcc8e6.png"
                  className="status-m-t-status-image"
                />
              )}
              <div className="status-m-t-status-container">
                <span className="status-m-t-status-container-texr">
                  {this.state.ToonsData && <span>{this.state.ToonsData.status}</span>}
                  <br></br>
                </span>
                <h1 className="status-m-t-status-container-heading">Daiki Toons</h1>
              </div>
            </div>
            <div className="status-daiki-closed-beta-status">
              {this.state.ClosedData && (
                <img
                  style={{ boxShadow: `0px 0px 10px 5px ${this.state.ClosedData.hex}` }}
                  alt="image"
                  src="https://cdn.discordapp.com/avatars/889334761796010014/5419f03a312d8d44344452de8c672055.png"
                  className="status-m-c-status-image"
                />
              )}
              <div className="status-m-c-status-container">
                <span className="status-m-c-status-container-text">
                  {this.state.ClosedData && <span>{this.state.ClosedData.status}</span>}
                  <br></br>
                </span>
                <h1 className="status-m-c-status-container-heading">
                  <span>Closed Beta</span>
                  <br></br>
                </h1>
              </div>
            </div>
          </div>
          <div className="status-status-container-1">
            <div className="status-daiki-status">
              {this.state.MainData && (
                <img
                  style={{ boxShadow: `0px 0px 10px 5px ${this.state.MainData.hex}` }}
                  alt="image"
                  src="https://cdn.discordapp.com/avatars/839287174482362438/cac55e100687c417cdddd25849a548af.png"
                  className="status-daiki-status-image"
                />
              )}
              <div className="status-daiki-status-container">
                <span className="status-daiki-status-container-text">
                  {this.state.MainData && <span>{this.state.MainData.status}</span>}
                  <br></br>
                </span>
                <h1 className="status-daiki-status-container-heading">Daiki</h1>
              </div>
            </div>
            <div className="status-daiki-beta-status">
              {this.state.BetaData && (
                <img
                  style={{ boxShadow: `0px 0px 10px 5px ${this.state.BetaData.hex}` }}
                  alt="image"
                  src="https://cdn.discordapp.com/avatars/889334761796010014/5419f03a312d8d44344452de8c672055.png"
                  className="status-b-status-image"
                />
              )}
              <div className="status-b-status-container">
                <span className="status-b-status-container-text">
                  {this.state.BetaData && <span>{this.state.BetaData.status}</span>}
                  <br></br>
                </span>
                <h1 className="status-b-status-container-heading">Daiki Beta</h1>
              </div>
            </div>
          </div>
          <div className="status-status-container-2">
            <div className="status-daiki-toons-status">
              {this.state.ToonsData && (
                <img
                  style={{ boxShadow: `0px 0px 10px 5px ${this.state.ToonsData.hex}` }}
                  alt="image"
                  src="https://cdn.discordapp.com/avatars/906675069760200724/549fe0c1a6666b7ed19ef4d5c2dcc8e6.png"
                  className="status-t-status-image"
                />
              )}
              <div className="status-t-status-container">
                <span className="status-t-status-container-text">
                  {this.state.ToonsData && <span>{this.state.ToonsData.status}</span>}
                  <br></br>
                </span>
                <h1 className="status-t-status-container-heading">Daiki Toons</h1>
              </div>
            </div>
            <div className="status-daiki-closed-beta-status1">
              {this.state.ClosedData && (
                <img
                  style={{ boxShadow: `0px 0px 10px 5px ${this.state.ClosedData.hex}` }}
                  alt="image"
                  src="https://cdn.discordapp.com/avatars/889334761796010014/5419f03a312d8d44344452de8c672055.png"
                  className="status-c-status-image"
                />
              )}
              <div className="status-c-status-container">
                <span className="status-c-status-container-text">
                  {this.state.ClosedData && <span>{this.state.ClosedData.status}</span>}
                  <br></br>
                </span>
                <h1 className="status-c-status-container-heading">
                  <span>Closed Beta</span>
                  <br></br>
                </h1>
              </div>
            </div>
          </div>
          <h1 className="status-partnered-text">
            <span>Partnered Discord Bots</span>
            <br></br>
          </h1>
          <div className="status-partnered-status-container-1">
            <div className="status-rebirth-bot-status">
              <img
                style={{ boxShadow: `0px 0px 10px 5px #99aab5` }}
                alt="image"
                src="https://cdn.discordapp.com/avatars/989293783009079338/76ffa44bc38c4fda9e8f1b683cb243fc.png"
                className="status-p-r-e-status-image"
              />
              <div className="status-p-r-e-status-container">
                <span className="status-p-r-e-status-container-text">
                  <span>Unknown Status</span>
                  <br></br>
                </span>
                <h1 className="status-p-r-e-status-container-heading">
                  <span>Rebirth</span>
                  <br></br>
                </h1>
              </div>
            </div>
          </div>
          <FooterContainer rootClassName="footer-container-root-class-name6"></FooterContainer>
        </div>
      </html>
    );
  }
}

export default Status;

{/* <div className="status-container">
  <NavbarContainer rootClassName="navbar-container-root-class-name7"></NavbarContainer>
  <h1 className="status-text">
    <span>Last Updated: {this.state.MainData && <span>{this.state.MainData.date}</span>}</span>
    <br></br>
  </h1>
  <div className="status-container01">
    <div className="status-daiki-status">
      {this.state.MainData && (
        <img
          style={{ boxShadow: `0px 0px 10px 5px ${this.state.MainData.hex}` }}
          alt="image"
          src="https://cdn.discordapp.com/avatars/839287174482362438/cac55e100687c417cdddd25849a548af.png"
          className="status-image"
        />
      )}
      <div className="status-container02">
        <span className="status-text03">
          {this.state.MainData && <span>{this.state.MainData.status}</span>}
          <br></br>
        </span>
        <h1 className="status-text06">Daiki</h1>
      </div>
    </div>
    <div className="status-daiki-beta-status">
      {this.state.BetaData && (
        <img
          style={{ boxShadow: `0px 0px 10px 5px ${this.state.BetaData.hex}` }}
          alt="image"
          src="https://cdn.discordapp.com/avatars/889334761796010014/5419f03a312d8d44344452de8c672055.png"
          className="status-image1"
        />
      )}
      <div className="status-container03">
        <span className="status-text07">
          {this.state.BetaData && <span>{this.state.BetaData.status}</span>}
          <br></br>
        </span>
        <h1 className="status-text10">Daiki Beta</h1>
      </div>
    </div>
    <div className="status-daiki-toons-status">
      {this.state.ToonsData && (
        <img
          style={{ boxShadow: `0px 0px 10px 5px ${this.state.ToonsData.hex}` }}
          alt="image"
          src="https://cdn.discordapp.com/avatars/906675069760200724/549fe0c1a6666b7ed19ef4d5c2dcc8e6.png"
          className="status-image2"
        />
      )}
      <div className="status-container04">
        <span className="status-text11">
          {this.state.ToonsData && <span>{this.state.ToonsData.status}</span>}
          <br></br>
        </span>
        <h1 className="status-text14">Daiki Toons</h1>
      </div>
    </div>
    <div className="status-daiki-closed-beta-status">
      {this.state.ClosedData && (
        <img
          style={{ boxShadow: `0px 0px 10px 5px ${this.state.ClosedData.hex}` }}
          alt="image"
          src="https://cdn.discordapp.com/avatars/889334761796010014/5419f03a312d8d44344452de8c672055.png"
          className="status-image3"
        />
      )}
      <div className="status-container05">
        <span className="status-text15">
          {this.state.ClosedData && <span>{this.state.ClosedData.status}</span>}
          <br></br>
        </span>
        <h1 className="status-text18">
          <span>Closed Beta</span>
          <br></br>
        </h1>
      </div>
    </div>
  </div>
  <div className="status-container06">
    <div className="status-daiki-status1">
      {this.state.MainData && (
        <img
          style={{ boxShadow: `0px 0px 10px 5px ${this.state.MainData.hex}` }}
          alt="image"
          src="https://cdn.discordapp.com/avatars/839287174482362438/cac55e100687c417cdddd25849a548af.png"
          className="status-image4"
        />
      )}
      <div className="status-container07">
        <span className="status-text21">
          {this.state.MainData && <span>{this.state.MainData.status}</span>}
          <br></br>
        </span>
        <h1 className="status-text24">Daiki</h1>
      </div>
    </div>
    <div className="status-daiki-beta-status1">
      {this.state.BetaData && (
        <img
          style={{ boxShadow: `0px 0px 10px 5px ${this.state.BetaData.hex}` }}
          alt="image"
          src="https://cdn.discordapp.com/avatars/889334761796010014/5419f03a312d8d44344452de8c672055.png"
          className="status-image5"
        />
      )}
      <div className="status-container08">
        <span className="status-text25">
          {this.state.BetaData && <span>{this.state.BetaData.status}</span>}
          <br></br>
        </span>
        <h1 className="status-text28">Daiki Beta</h1>
      </div>
    </div>
  </div>
  <div className="status-container09">
    <div className="status-daiki-toons-status1">
      {this.state.ToonsData && (
        <img
          style={{ boxShadow: `0px 0px 10px 5px ${this.state.ToonsData.hex}` }}
          alt="image"
          src="https://cdn.discordapp.com/avatars/906675069760200724/549fe0c1a6666b7ed19ef4d5c2dcc8e6.png"
          className="status-image6"
        />
      )}
      <div className="status-container10">
        <span className="status-text29">
          {this.state.ToonsData && <span>{this.state.ToonsData.status}</span>}
          <br></br>
        </span>
        <h1 className="status-text32">Daiki Toons</h1>
      </div>
    </div>
    <div className="status-daiki-closed-beta-status1">
      {this.state.ClosedData && (
        <img
          style={{ boxShadow: `0px 0px 10px 5px ${this.state.ClosedData.hex}` }}
          alt="image"
          src="https://cdn.discordapp.com/avatars/889334761796010014/5419f03a312d8d44344452de8c672055.png"
          className="status-image7"
        />
      )}
      <div className="status-container11">
        <span className="status-text33">
          {this.state.ClosedData && <span>{this.state.ClosedData.status}</span>}
          <br></br>
        </span>
        <h1 className="status-text36">
          <span>Closed Beta</span>
          <br></br>
        </h1>
      </div>
    </div>
  </div>
  <h1 className="status-text39">
    <span>Partnered Discord Bots</span>
    <br></br>
  </h1>
  <div className="status-container12">
    <div className="status-rebirth-bot-status">
      <img
        alt="image"
        src="https://cdn.discordapp.com/avatars/989293783009079338/76ffa44bc38c4fda9e8f1b683cb243fc.png"
        image_src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&amp;ixlib=rb-1.2.1&amp;h=1000"
        className="status-image8"
      />
      <div className="status-container13">
        <span className="status-text42">
          <span>Unknown</span>
          <br></br>
        </span>
        <h1 className="status-text45">
          <span>Rebirth</span>
          <br></br>
        </h1>
      </div>
    </div>
  </div>
  <FooterContainer rootClassName="footer-container-root-class-name6"></FooterContainer>
</div> */}