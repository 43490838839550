import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './privacy-policy.css'

const PrivacyPolicy = (props) => {
  return (
    <html>
      <head>
        <title>Privacy Policy - Daiki Development</title>
        <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes='32x32' />
        <meta property='og:title' content="Daiki Development" />
        <meta
          property="og:description"
          content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
        />
        <meta property='og:url' content="https://daiki-bot.xyz/" />
        <meta property='og:image' content='/playground_assets/copy_of_2022 [1].png' />
      </head>
      <div className="privacy-policy-container">
        <Helmet>
          <title>Privacy Policy - Daiki Development</title>
          <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes="32x32" />
          <meta
            property="og:title"
            content="Privacy-Policy - Daiki Development"
          />
          <meta
            property="og:description"
            content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
          />
        </Helmet>
        <NavbarContainer rootClassName="navbar-container-root-class-name4"></NavbarContainer>
        <div className="privacy-policy-container1">
          <h1 className="privacy-policy-text">Privacy Policy</h1>
          <div className="privacy-policy-separator"></div>
          <div className="privacy-policy-container2">
            <div className="privacy-policy-container3">
              <div className="privacy-policy-feature-card">
                <span className="privacy-policy-text01">
                  <span>
                    The use of this application (&quot;Bot&quot;) in a server
                    requires the collection of some specific user data
                    (&quot;Data&quot;). The Data collected includes Discord User
                    Id, Discord Username, Discord User Discriminator. Use of the
                    Bot is considered an agreement to the terms of this Policy.
                  </span>
                  <br></br>
                </span>
              </div>
              <div className="privacy-policy-feature-card1">
                <h2 className="privacy-policy-text04">Access To Data</h2>
                <span className="privacy-policy-text05">
                  <span>
                    Access to this collected data is only permitted to Bot
                    Developers (BigTDM 10, Cool Squidd), and only in the scope
                    required for the development, testing, and implementation of
                    features of the application (“Bot”). Data is not sold,
                    provided to, or shared with a third party, except where
                    required by Terms of Service agreement. You can view the data
                    upon request from (BigTDM 10)
                  </span>
                  <br></br>
                </span>
              </div>
              <div className="privacy-policy-feature-card2">
                <h2 className="privacy-policy-text08">Storage of Data</h2>
                <span className="privacy-policy-text09">
                  <span>
                    Data is stored in a MongoDB database. The database is secured
                    to prevent external access, however no guarantee is provided
                    and the application (“Bot”) owners assume no liability for the
                    unintentional or malicious breach of Data. In the event of an
                    unauthorized Data access, users will be notified through the
                    Discord client application (“Discord Bot”)
                  </span>
                  <br></br>
                </span>
              </div>
              <div className="privacy-policy-feature-card3">
                <h2 className="privacy-policy-text12">User Rights</h2>
                <span className="privacy-policy-text13">
                  <span>
                    At any time, you have the right to request to view the Data
                    pertaining to your Discord account. You may submit a request
                    through the Discord DM’s with the application (“Bot”)
                    developers (BigTDM 10, Cool Squidd). You have the right to
                    request the removal of relevant Data.
                  </span>
                  <br></br>
                </span>
              </div>
              <div className="privacy-policy-feature-card4">
                <h2 className="privacy-policy-text16">Underage Users</h2>
                <span className="privacy-policy-text17">
                  <span>
                    The use of the application (“Bot”) is not permitted for minors
                    under the age of 13, or under the age of legal consent for
                    their country. This is in compliance with the
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a
                    href="https://discord.com/terms"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="privacy-policy-link"
                  >
                    Discord Terms Of Service
                  </a>
                  <span>
                    . No information will be knowingly stored from an underage
                    user. If it is found out that a user is underage we will take
                    all necessary action to delete the stored data and or prevent
                    you from using the application (“Bot”). Along with contacting
                    discord with information such as proof of underage and your
                    discord username / Id.
                  </span>
                  <br className="privacy-policy-text20"></br>
                  <br></br>
                </span>
              </div>
              <div className="privacy-policy-feature-card5">
                <h2 className="privacy-policy-text22">Questions</h2>
                <span className="privacy-policy-text23">
                  <span>
                    If you have any questions or are concerned about what data
                    might be being stored from your account contact @BigTDM 10.
                    For more information check the
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a
                    href="https://discord.com/terms"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="privacy-policy-link1"
                  >
                    Discord Terms Of Service
                  </a>
                  <span>.</span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
        <FooterContainer rootClassName="footer-container-root-class-name3"></FooterContainer>
      </div>
    </html>
  )
}

export default PrivacyPolicy
