import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './documentation.css'

const Documentation = (props) => {
  return (
    <html>
      <head>
        <title>Docs - Daiki Development</title>
        <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes='32x32' />
        <meta property='og:title' content="Docs - Daiki Development" />
        <meta
          property="og:description"
          content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
        />
        <meta property='og:url' content="https://daiki-bot.xyz/" />
        <meta property='og:image' content='/playground_assets/copy_of_2022 [1].png' />
      </head>
      <div className="documentation-container">
        <Helmet>
          <title>Docs - Daiki Development</title>
          <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes="32x32" />
          <meta property="og:title" content="Daiki | Docs" />
          <meta
            property="og:description"
            content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
          />
        </Helmet>
        <NavbarContainer rootClassName="navbar-container-root-class-name2"></NavbarContainer>
        <div className="documentation-documentation-select">
          <div className="documentation-row-1">
            <a href="#information" className="documentation-link">
              <div className="documentation-feature-card button">
                <svg viewBox="0 0 1024 1024" className="documentation-icon">
                  <path d="M448 304c0-26.4 21.6-48 48-48h32c26.4 0 48 21.6 48 48v32c0 26.4-21.6 48-48 48h-32c-26.4 0-48-21.6-48-48v-32z"></path>
                  <path d="M640 768h-256v-64h64v-192h-64v-64h192v256h64z"></path>
                  <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"></path>
                </svg>
                <h2 className="documentation-text">Information</h2>
                <span className="documentation-text001">
                  All current information on Daiki such as version and current
                  features
                </span>
              </div>
            </a>
            <a href="#permissions" className="documentation-link01">
              <div className="documentation-feature-card01 button">
                <svg viewBox="0 0 1024 1024" className="documentation-icon04">
                  <path d="M704 0c-176.73 0-320 143.268-320 320 0 20.026 1.858 39.616 5.376 58.624l-389.376 389.376v192c0 35.346 28.654 64 64 64h64v-64h128v-128h128v-128h128l83.042-83.042c34.010 12.316 70.696 19.042 108.958 19.042 176.73 0 320-143.268 320-320s-143.27-320-320-320zM799.874 320.126c-53.020 0-96-42.98-96-96s42.98-96 96-96 96 42.98 96 96-42.98 96-96 96z"></path>
                </svg>
                <h2 className="documentation-text002">Permissions</h2>
                <span className="documentation-text003">
                  Here you can find what permissions that Daiki needs to function
                  properly
                </span>
              </div>
            </a>
            <a href="#setup" className="documentation-link02">
              <div className="documentation-feature-card02 button">
                <svg viewBox="0 0 1024 1024" className="documentation-icon06">
                  <path d="M512 662q62 0 106-44t44-106-44-106-106-44-106 44-44 106 44 106 106 44zM830 554l90 70q14 10 4 28l-86 148q-8 14-26 8l-106-42q-42 30-72 42l-16 112q-4 18-20 18h-172q-16 0-20-18l-16-112q-38-16-72-42l-106 42q-18 6-26-8l-86-148q-10-18 4-28l90-70q-2-14-2-42t2-42l-90-70q-14-10-4-28l86-148q8-14 26-8l106 42q42-30 72-42l16-112q4-18 20-18h172q16 0 20 18l16 112q38 16 72 42l106-42q18-6 26 8l86 148q10 18-4 28l-90 70q2 14 2 42t-2 42z"></path>
                </svg>
                <h2 className="documentation-text004">Setup</h2>
                <span className="documentation-text005">
                  Here you can find way to set-up Daiki and get extra help
                </span>
              </div>
            </a>
          </div>
          <div className="documentation-row-2">
            <a href="#help" className="documentation-link03">
              <div className="documentation-feature-card03 button">
                <svg viewBox="0 0 1024 1024" className="documentation-icon08">
                  <path d="M512 256q70 0 120 50t50 120q0 54-64 111t-64 103h-84q0-46 20-79t44-48 44-37 20-50q0-34-26-59t-60-25-60 25-26 59h-84q0-70 50-120t120-50zM512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM470 768v-86h84v86h-84z"></path>
                </svg>
                <h2 className="documentation-text006">Help</h2>
                <span className="documentation-text007">
                  Here you can find more ways to get extra help with Daiki
                </span>
              </div>
            </a>
            <a href="#facts" className="documentation-link04">
              <div className="documentation-feature-card04 button">
                <svg viewBox="0 0 1024 1024" className="documentation-icon10">
                  <path d="M298 214h598v84h-598v-84zM298 554v-84h598v84h-598zM298 810v-84h598v84h-598zM170 704q26 0 45 19t19 45-19 45-45 19-45-19-19-45 19-45 45-19zM170 192q26 0 45 18t19 46-19 46-45 18-45-18-19-46 19-46 45-18zM170 448q26 0 45 18t19 46-19 46-45 18-45-18-19-46 19-46 45-18z"></path>
                </svg>
                <h2 className="documentation-text008">Facts</h2>
                <span className="documentation-text009">
                  Here you can find fun facts about Daiki
                </span>
              </div>
            </a>
            <a href="#resources" className="documentation-link05">
              <div className="documentation-feature-card05 button">
                <svg viewBox="0 0 1024 1024" className="documentation-icon12">
                  <path d="M917.806 229.076c-22.212-30.292-53.174-65.7-87.178-99.704s-69.412-64.964-99.704-87.178c-51.574-37.82-76.592-42.194-90.924-42.194h-496c-44.112 0-80 35.888-80 80v864c0 44.112 35.888 80 80 80h736c44.112 0 80-35.888 80-80v-624c0-14.332-4.372-39.35-42.194-90.924zM785.374 174.626c30.7 30.7 54.8 58.398 72.58 81.374h-153.954v-153.946c22.984 17.78 50.678 41.878 81.374 72.572zM896 944c0 8.672-7.328 16-16 16h-736c-8.672 0-16-7.328-16-16v-864c0-8.672 7.328-16 16-16 0 0 495.956-0.002 496 0v224c0 17.672 14.326 32 32 32h224v624z"></path>
                  <path d="M736 832h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z"></path>
                  <path d="M736 704h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z"></path>
                  <path d="M736 576h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z"></path>
                </svg>
                <h2 className="documentation-text010">Resources</h2>
                <span className="documentation-text011">
                  Here you can see what programs we used to create and update
                  Daiki
                </span>
              </div>
            </a>
          </div>
        </div>
        <div id="information" className="documentation-information-docs">
          <h1 className="documentation-text012">Information</h1>
          <div className="documentation-separator"></div>
          <div className="documentation-container01">
            <div className="documentation-container02">
              <div className="documentation-feature-card06">
                <h2 className="documentation-text013">Daiki Bot</h2>
                <span className="documentation-text014">
                  <span>
                    • Daiki is a Discord bot created with
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a
                    href="https://discord.js.org"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="documentation-link06"
                  >
                    Discord.JS
                  </a>
                  <br></br>
                  <span>
                    • Want to invite Daiki?
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a
                    href="https://short.daiki-bot.xyz/daiki/invite"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="documentation-link07"
                  >
                    Invite Here
                  </a>
                  <br></br>
                  <span>
                    • Need help with Daiki?
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a
                    href="https://daiki-bot.xyz/support"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="documentation-link08"
                  >
                    Get Support Here
                  </a>
                  <br></br>
                </span>
              </div>
              <div className="documentation-feature-card07">
                <h2 className="documentation-text021">
                  <span className="documentation-text022">Requirements</span>
                  <br></br>
                </h2>
                <span className="documentation-text024">
                  Daiki has no requirements besides needing the correct
                  permissions to function properly.
                </span>
              </div>
              <div className="documentation-feature-card08">
                <h2 className="documentation-text025">
                  <span className="documentation-text026">Current Features</span>
                  <br></br>
                </h2>
                <span className="documentation-text028">
                  <span>• Anti-Spam</span>
                  <br></br>
                  <span>• Warnings</span>
                  <br></br>
                  <span>• Lockdown</span>
                  <br></br>
                </span>
              </div>
              <div className="documentation-feature-card09">
                <h2 className="documentation-text035">Upcoming Features</h2>
                <span className="documentation-text036">
                  <span>• Auto-Role</span>
                  <br></br>
                  <span>• Reaction Roles</span>
                  <br></br>
                  <span>
                    • Logging System (Leave, Join, Channel (Create, Updated,
                    Deleted), Kick, Ban, Message Delete, Message Edit)
                  </span>
                  <br></br>
                  <span>• Welcome System</span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="permissions" className="documentation-permissions-docs">
          <h1 className="documentation-text045">
            <span className="documentation-text046">Permissions</span>
            <br></br>
          </h1>
          <div className="documentation-separator1"></div>
          <div className="documentation-container03">
            <div className="documentation-container04">
              <div className="documentation-feature-card10">
                <h2 className="documentation-text048">
                  <span className="documentation-text049">Bot Permissions</span>
                  <br></br>
                </h2>
                <span className="documentation-text051">
                  <span>
                    All permissions listed below are required for Daiki to
                    function properly.
                  </span>
                  <br></br>
                  <br></br>
                  <span>• Manage Server</span>
                  <br></br>
                  <span>• Manage Roles</span>
                  <br></br>
                  <span>• Manage Channels</span>
                  <br></br>
                  <span>• Kick Members</span>
                  <br></br>
                  <span>• Ban Members</span>
                  <br></br>
                  <span>• Create Invite</span>
                  <br></br>
                  <span>• Manage Nicknames</span>
                  <br></br>
                  <span>• Change Nicknames</span>
                  <br></br>
                  <span>• Manage Webhooks</span>
                  <br></br>
                  <span>• View Audit Logs</span>
                  <br></br>
                  <span>• Read Messages</span>
                  <br></br>
                  <span>• Moderate Members</span>
                  <br></br>
                  <span>• Send Messages</span>
                  <br></br>
                  <span>• Manage Messages</span>
                  <br></br>
                  <span>• Embed Links</span>
                  <br></br>
                  <span>• Attach Files</span>
                  <br></br>
                  <span>• Read Message History</span>
                  <br></br>
                  <span>• Mention @everyone, @here, and All Roles</span>
                  <br></br>
                  <span>• Add Reactions</span>
                  <br></br>
                  <span>• Use External Emojis</span>
                  <br></br>
                  <span>• Connect</span>
                  <br></br>
                  <span>• Speak</span>
                  <br></br>
                  <span>• Mute Members</span>
                  <br></br>
                  <span>• Deafen Members</span>
                  <br></br>
                  <span>
                    • Priority Speaker
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <span>• Use Voice Activity</span>
                  <br></br>
                </span>
              </div>
              <div className="documentation-feature-card11">
                <h2 className="documentation-text107"></h2>
                <span className="documentation-text108">
                  <span>• Move Members</span>
                  <br></br>
                  <span>• Embed Links</span>
                  <br></br>
                  <span>• Attach Files</span>
                  <br></br>
                  <span>• Read Message History</span>
                  <br></br>
                  <span>• Mention @everyone, @here, and All Roles</span>
                  <br></br>
                  <span>• Add Reactions</span>
                  <br></br>
                  <span>• Use External Emojis</span>
                  <br></br>
                  <span>• Connect</span>
                  <br></br>
                  <span>• Speak</span>
                  <br></br>
                  <span>• Mute Members</span>
                  <br></br>
                  <span>• Deafen Members</span>
                  <br></br>
                  <span>• Move Members</span>
                  <br></br>
                  <span>
                    • Priority Speaker
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <span>• Use Voice Activity</span>
                  <br></br>

                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="setup" className="documentation-setup-docs">
          <h1 className="documentation-text135">Setup</h1>
          <div className="documentation-separator2"></div>
          <div className="documentation-container05">
            <div className="documentation-container06">
              <div className="documentation-feature-card12">
                <h2 className="documentation-text136">
                  <span className="documentation-text137">Requirements</span>
                  <br></br>
                </h2>
                <span className="documentation-text139">
                  <span>
                    Daiki has no requirements besides needing the correct
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a href="#permissions" className="documentation-link09">
                    permissions
                  </a>
                  <span> to function properly.</span>
                </span>
              </div>
              <div className="documentation-feature-card13">
                <h2 className="documentation-text142">
                  <span className="documentation-text143">
                    Let&apos;s Set-up Daiki!
                  </span>
                  <br></br>
                </h2>
                <span className="documentation-text145">
                  <span>1: Invite Daiki</span>
                  <br></br>
                  <span>
                    2: Double check Daiki has all of the correct
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a href="#permissions" className="documentation-link10">
                    permissions
                  </a>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="help" className="documentation-help-docs">
          <h1 className="documentation-text150">Help</h1>
          <div className="documentation-separator3"></div>
          <div className="documentation-container07">
            <div className="documentation-container08">
              <div className="documentation-feature-card14">
                <h2 className="documentation-text151">Daiki Links</h2>
                <span className="documentation-text152">
                  <span>
                    • Daiki is a Discord bot created with
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a
                    href="https://discord.js.org"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="documentation-link11"
                  >
                    Discord.JS
                  </a>
                  <br></br>
                  <span>
                    • Want to invite Daiki?
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a
                    href="https://short.daiki-bot.xyz/daiki/invite"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="documentation-link12"
                  >
                    Invite Here
                  </a>
                  <br></br>
                  <span>
                    • Need help with Daiki?
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a
                    href="https://daiki-bot.xyz/support"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="documentation-link13"
                  >
                    Get Support Here
                  </a>
                  <br></br>
                </span>
              </div>
              <div className="documentation-feature-card15">
                <h2 className="documentation-text159">
                  <span className="documentation-text160">Troubleshooting</span>
                  <br></br>
                </h2>
                <span className="documentation-text162">
                  <span>
                    Daiki not responding to a command? Make sure that Daiki is not
                    under a update or down, also make sure Daiki has all of the
                    correct
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a href="#permissions" className="documentation-link14">
                    permissions
                  </a>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="facts" className="documentation-facts-docs">
          <h1 className="documentation-text165">Facts</h1>
          <div className="documentation-separator4"></div>
          <div className="documentation-container09">
            <div className="documentation-container10">
              <div className="documentation-feature-card16">
                <h2 className="documentation-text166">When was Daiki Created?</h2>
                <span className="documentation-text167">
                  <span>
                    The Daiki Development project was started on May 4th, 2021
                  </span>
                  <br></br>
                </span>
              </div>
              <div className="documentation-feature-card17">
                <h2 className="documentation-text170">
                  <span className="documentation-text171">
                    Why was Daiki Created?
                  </span>
                  <br></br>
                </h2>
                <span className="documentation-text173">
                  We created Daiki to be like most Discord bots but with out all
                  of the premium paying, so all of our supporters can get the full
                  experience with out the cost.
                </span>
              </div>
              <div className="documentation-feature-card18">
                <h2 className="documentation-text174">
                  <span className="documentation-text175">
                    How many members use Daiki?
                  </span>
                  <br></br>
                </h2>
                <span className="documentation-text177">
                  <span>
                    At the moment, there are over 100,000 member use Daiki!
                  </span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="resources" className="documentation-resources-docs">
          <h1 className="documentation-text180">Resources</h1>
          <div className="documentation-separator5"></div>
          <div className="documentation-container11">
            <div className="documentation-container12">
              <div className="documentation-feature-card19">
                <h2 className="documentation-text181">Packages Used</h2>
                <span className="documentation-text182">
                  <span>
                    Discord.JS - This is a api used to have Daiki communicate with
                    Discord and make Daiki functional.
                  </span>
                  <br></br>
                  <span className="documentation-text185">
                    Daiki uses version: 13.1.0
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Mongoose - Database application that can be used for small
                    applications and is free but for better quality and
                    preformance you will need to pay. To learn more please visit
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a
                    href="https://mongodb.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="documentation-link15"
                  >
                    mongodb.com
                  </a>
                  <br></br>
                  <span className="documentation-text190">
                    Daiki uses version: 6.0.12
                  </span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
        <FooterContainer></FooterContainer>
      </div>
    </html>
  )
}

export default Documentation
