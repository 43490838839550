import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './commands.css'

const Commands = (props) => {
  return (
    <html>
      <head>
        <title>Commands - Daiki Development</title>
        <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes='32x32' />
        <meta property='og:title' content="Commands - Daiki Development" />
        <meta
          property="og:description"
          content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
        />
        <meta property='og:url' content="https://daiki-bot.xyz/" />
        <meta property='og:image' content='/playground_assets/copy_of_2022 [1].png' />
      </head>
      <div className="commands-container">
        <Helmet>
          <title>Commands - Daiki Development</title>
          <link rel="shortcut icon" href="/playground_assets/copy_of_2022 [1].png" type="icon/png" sizes="32x32" />
          <meta property="og:title" content="Daiki | Commands" />
          <meta
            property="og:description"
            content="Daiki Development is a small group of people wanting to make Discord better. Daiki Development started on May 4th, 2021, our first bot launched was called Daiki."
          />
        </Helmet>
        <NavbarContainer rootClassName="navbar-container-root-class-name6"></NavbarContainer>
        <div className="commands-commands">
          <h1 className="commands-text">
            <span className="commands-text001">Daiki V14.6 Commands</span>
            <br></br>
          </h1>
          <div className="commands-separator"></div>
          <div className="commands-commands-sec-1">
            <div className="commands-container01">
              <div className="commands-command">
                <div className="commands-container02">
                  <h2 className="commands-text003">
                    <span>8-Ball</span>
                    <br></br>
                  </h2>
                  <span className="commands-text006">
                    <span>
                      Ask Daiki a question and you shall receive an 8-ball answer
                      (Options: Question)
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command01">
                <div className="commands-container03">
                  <h2 className="commands-text009">
                    <span>AFK</span>
                    <br></br>
                  </h2>
                  <span className="commands-text012">
                    AFK is short for away from the keyboard and it will allow
                    Discord to send custom messages to other users who mention you
                    when you are not physically in front of your computer. This is
                    a great option when you have to use the restroom or grab a
                    short pause for lunch.
                  </span>
                </div>
              </div>
              <div className="commands-command02">
                <div className="commands-container04">
                  <h2 className="commands-text013">
                    <span>APOD</span>
                    <br></br>
                  </h2>
                  <span className="commands-text016">
                    Astronomy Picture of the Day from NASA
                  </span>
                </div>
              </div>
              <div className="commands-command03">
                <div className="commands-container05">
                  <h2 className="commands-text017">
                    <span>Ban</span>
                    <br></br>
                  </h2>
                  <span className="commands-text020">
                    <span>Ban user from server (Options: Target, Reason)</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command04">
                <div className="commands-container06">
                  <h2 className="commands-text023">
                    <span>Clear</span>
                    <br></br>
                  </h2>
                  <span className="commands-text026">
                    <span>
                      Deletes a specified number of messages from a channel or
                      user (Options: Amount, Target)
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command05">
                <div className="commands-container07">
                  <h2 className="commands-text029">
                    <span>Convert</span>
                    <br></br>
                  </h2>
                  <span className="commands-text032">
                    <span>converts between different currencies</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command06">
                <div className="commands-container08">
                  <h2 className="commands-text035">
                    <span>Crypto</span>
                    <br></br>
                  </h2>
                  <span className="commands-text038">
                    <span>
                      retrieves and displays the current price and other
                      information about a specific cryptocurrency
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command07">
                <div className="commands-container09">
                  <h2 className="commands-text041">
                    <span>Embed</span>
                    <br></br>
                  </h2>
                  <span className="commands-text044">
                    <span>
                      Send an embed in a given chat (Options: Color, Channel)
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command08">
                <div className="commands-container10">
                  <h2 className="commands-text047">
                    <span>Fact</span>
                    <br></br>
                  </h2>
                  <span className="commands-text050">
                    <span>Displays a random interesting fact</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command09">
                <div className="commands-container11">
                  <h2 className="commands-text053">
                    <span>First Message</span>
                    <br></br>
                  </h2>
                  <span className="commands-text056">
                    <span>Shows first message in the current channel</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command10">
                <div className="commands-container12">
                  <h2 className="commands-text059">
                    <span>Flip Coin</span>
                    <br></br>
                  </h2>
                  <span className="commands-text062">
                    <span>Flips the coin</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command11">
                <div className="commands-container13">
                  <h2 className="commands-text065">
                    <span>Password Generator</span>
                    <br></br>
                  </h2>
                  <span className="commands-text068">
                    <span>
                      generates strong and secure passwords with customizable
                      options like length and character types. It helps users
                      create secure passwords.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command12">
                <div className="commands-container14">
                  <h2 className="commands-text071">
                    <span>Hug</span>
                    <br></br>
                  </h2>
                  <span className="commands-text074">
                    <span>
                      Sends a virtual hug to the mentioned user. Options: User
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command13">
                <div className="commands-container15">
                  <h2 className="commands-text077">
                    <span>Image</span>
                    <br></br>
                  </h2>
                  <span className="commands-text080">
                    <span>
                      Searches for an image based on the provided keyword and
                      displays it. Options: keyword
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command14">
                <div className="commands-container16">
                  <h2 className="commands-text083">
                    <span>Information</span>
                    <br></br>
                  </h2>
                  <span className="commands-text086">
                    <span>
                      User - This gets basic user information (Options: User)
                    </span>
                    <br></br>
                    <span>Server - This shows server information</span>
                    <br></br>
                    <span>
                      Permissions - This gets user permission information
                      (Options: Target)
                    </span>
                    <br></br>
                    <span>
                      Role - This gets user role information (Options: Role)
                    </span>
                    <br></br>
                    <span>
                      Bot - This command will show you a list of bot stats that
                      are currently available
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command15">
                <div className="commands-container17">
                  <h2 className="commands-text097">
                    <span>Invite</span>
                    <br></br>
                  </h2>
                  <span className="commands-text100">
                    <span>Invite Daiki to another one of your servers!</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command16">
                <div className="commands-container18">
                  <h2 className="commands-text103">
                    <span>Joke</span>
                    <br></br>
                  </h2>
                  <span className="commands-text106">
                    <span>Fetches and displays random joke</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command17">
                <div className="commands-container19">
                  <h2 className="commands-text109">
                    <span>Kick</span>
                    <br></br>
                  </h2>
                  <span className="commands-text112">
                    <span>
                      Kick a user from the discord server (Options: Target,
                      Reason)
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command18">
                <div className="commands-container20">
                  <h2 className="commands-text115">
                    <span>Meme</span>
                    <br></br>
                  </h2>
                  <span className="commands-text118">
                    Sends funny meme, from reddit
                  </span>
                </div>
              </div>
              <div className="commands-command19">
                <div className="commands-container21">
                  <h2 className="commands-text119">
                    <span>Mute</span>
                    <br></br>
                  </h2>
                  <span className="commands-text122">
                    <span>
                      Mutes a given user (Options: Minutes, Target, Reason
                      &lt;Optional&gt;)
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command20">
                <div className="commands-container22">
                  <h2 className="commands-text125">
                    <span>QR Code Generator</span>
                    <br></br>
                  </h2>
                  <span className="commands-text128">
                    <span>generates QR codes for specified text or URLs</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command21">
                <div className="commands-container23">
                  <h2 className="commands-text131">
                    <span>Quote</span>
                    <br></br>
                  </h2>
                  <span className="commands-text134">
                    <span>
                      Retrieves and displays a random inspirational or
                      motivational quote
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command22">
                <div className="commands-container24">
                  <h2 className="commands-text137">
                    <span>Recipe Finder</span>
                    <br></br>
                  </h2>
                  <span className="commands-text140">
                    <span>
                      search for recipes based on ingredients or cuisine type. The
                      bot fetches and displays relevant recipes from a recipe API.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command23">
                <div className="commands-container25">
                  <h2 className="commands-text143">
                    <span>Reddit</span>
                    <br></br>
                  </h2>
                  <span className="commands-text146">
                    <span>
                      Fetches and displays a random post from a specific
                      subreddit. Options: Subreddit
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command24">
                <div className="commands-container26">
                  <h2 className="commands-text149">
                    <span>Role</span>
                    <br></br>
                  </h2>
                  <span className="commands-text152">
                    <span>
                      Add - Add a role to any given member (Options: Role)
                    </span>
                    <br></br>
                    <span>
                      Remove - Remove a role from a given member (Options: Role)
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command25">
                <div className="commands-container27">
                  <h2 className="commands-text157">
                    <span>RPS</span>
                    <br></br>
                  </h2>
                  <span className="commands-text160">
                    This is a pretty funny new command that allows you to play
                    rock – paper – scissors with other users. Options: Choice
                  </span>
                </div>
              </div>
              <div className="commands-command26">
                <div className="commands-container28">
                  <h2 className="commands-text161">
                    <span>Say</span>
                    <br></br>
                  </h2>
                  <span className="commands-text164">
                    <span>
                      Send a message in a given chat (Options: Channel
                      &lt;Optional&gt;)
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command27">
                <div className="commands-container29">
                  <h2 className="commands-text167">
                    <span>Server Avatar</span>
                    <br></br>
                  </h2>
                  <span className="commands-text170">
                    <span>
                      Shows server avatar (Options: Target &lt;Optional&gt;
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command28">
                <div className="commands-container30">
                  <h2 className="commands-text173">Setup</h2>
                  <span className="commands-text174">
                    <span>
                      Configured - Shows all configured settings for the discord
                      server
                    </span>
                    <br></br>
                    <span>
                      Reset - Reset Server Configuration (Options: Setting *Which
                      setup setting*)
                    </span>
                    <br></br>
                    <span>
                      Warnings - Configure the way warning commands are interacted
                      with (Options: Role)
                    </span>
                    <br></br>
                    <span>
                      Mute - Configure the mute commands are interacted with
                      (Options: Role, Warn on Mute)
                    </span>
                    <br></br>
                    <span>
                      Kick - Configure the way kick commands are interacted with
                      (Options: Role)
                    </span>
                    <br></br>
                    <span>
                      Ban - Configure the way ban commands are interacted with
                      (Options: Role)
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command29">
                <div className="commands-container31">
                  <h2 className="commands-text187">
                    <span>Shorten</span>
                    <br></br>
                  </h2>
                  <span className="commands-text190">
                    <span>shortens long URLs</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command30">
                <div className="commands-container32">
                  <h2 className="commands-text193">
                    <span>Slowmode</span>
                    <br></br>
                  </h2>
                  <span className="commands-text196">
                    <span>
                      Set a slowmode in a channel (Options: Time, Reason
                      &lt;Optional&gt;)
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command31">
                <div className="commands-container33">
                  <h2 className="commands-text199">
                    <span>Suggestion</span>
                    <br></br>
                  </h2>
                  <span className="commands-text202">
                    <span>
                      Daiki can be given suggestions that will be seen by the main
                      developer of Daiki for real good Ideas to improve Daiki.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command32">
                <div className="commands-container34">
                  <h2 className="commands-text205">
                    <span>Support</span>
                    <br></br>
                  </h2>
                  <span className="commands-text208">
                    <span>Show a button to webpage for support</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command33">
                <div className="commands-container35">
                  <h2 className="commands-text211">
                    <span>Unban</span>
                    <br></br>
                  </h2>
                  <span className="commands-text214">
                    <span>
                      Unban a user from this discord server (Options: Target,
                      Reason)
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command34">
                <div className="commands-container36">
                  <h2 className="commands-text217">
                    <span>Unmute</span>
                    <br></br>
                  </h2>
                  <span className="commands-text220">
                    <span>Unmute a given user (Options: User, Reason)</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command35">
                <div className="commands-container37">
                  <h2 className="commands-text223">
                    <span>Urban</span>
                    <br></br>
                  </h2>
                  <span className="commands-text226">
                    <span>
                      Searches the Urban Dictionary for the provided term and
                      displays the definition Options: Term
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command36">
                <div className="commands-container38">
                  <h2 className="commands-text229">
                    <span>Voted</span>
                    <br></br>
                  </h2>
                  <span className="commands-text232">
                    <span>
                      Have you voted for Daiki on top.gg? Let&apos;s find out!
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command37">
                <div className="commands-container39">
                  <h2 className="commands-text235">
                    <span>Warning</span>
                    <br></br>
                  </h2>
                  <span className="commands-text238">
                    <span>
                      Show - Shows all warnings on a user (Options: User)
                    </span>
                    <br></br>
                    <span>Add - Warn a user (Options: Target, Reason)</span>
                    <br></br>
                    <span>
                      Remove - Remove a warning by ID from a user
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>(Options: Target, Warning ID)</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command38">
                <div className="commands-container40">
                  <h2 className="commands-text247">
                    <span>Weather System</span>
                    <br></br>
                  </h2>
                  <span className="commands-text250">
                    <span>
                      Many different weather APIs, such as
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <a
                      href="https://www.weatherapi.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="commands-link"
                    >
                      Weather API
                    </a>
                    <span>
                      {' '}
                      and
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <a
                      href="https://openweathermap.org/api"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="commands-link1"
                    >
                      Open Weather Map
                    </a>
                    <span>
                      , could be used to provide weather embeds to a discord
                      server. You could use slash commands to fetch the current
                      weather, or make a daily weather report channel if a discord
                      server is based in a single area.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command39">
                <div className="commands-container41">
                  <h2 className="commands-text255">
                    <span>Who Is</span>
                    <br></br>
                  </h2>
                  <span className="commands-text258">
                    <span>This gets basic user information (Options: User)</span>
                    <br></br>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <span className="commands-text261">
            <span>Want to see more commands here?</span>
            <br></br>
            <span>
              Fill out this
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <a
              href="https://forms.gle/zPRq39NNgXnzpcF1A"
              target="_blank"
              rel="noreferrer noopener"
              className="commands-link2"
            >
              Google Form
            </a>
            <span> to suggest a command!</span>
            <br></br>
          </span>
        </div>
        <div id="new" className="commands-upcoming-commands">
          <h1 className="commands-text267">
            <span className="commands-text268">
              Upcoming Daiki Commands &amp; Systems
            </span>
            <br></br>
          </h1>
          <div className="commands-separator1"></div>
          <div className="commands-commands-sec-11">
            <div className="commands-container42">
              <div className="commands-command40">
                <div className="commands-container43">
                  <h2 className="commands-text270">
                    <span>Remind Me</span>
                    <br></br>
                  </h2>
                  <span className="commands-text273">
                    This way you will be reminded of mentionable events, games, or
                    challenges at a certain time.
                  </span>
                </div>
              </div>
              <div className="commands-command41">
                <div className="commands-container44">
                  <h2 className="commands-text274">
                    <span>Reaction Based Games</span>
                    <br></br>
                  </h2>
                  <span className="commands-text277">
                    <span>
                      play simple games using reactions, such as trivia, hangman,
                      or tic-tac-toe.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command42">
                <div className="commands-container45">
                  <h2 className="commands-text280">
                    <span>Countdown Timer</span>
                    <br></br>
                  </h2>
                  <span className="commands-text283">
                    <span>
                      starts a countdown timer for a specified duration. Users can
                      use it for events, reminders, or time-sensitive tasks.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command43">
                <div className="commands-container46">
                  <h2 className="commands-text286">
                    <span>Automated Role Assignment</span>
                    <br></br>
                  </h2>
                  <span className="commands-text289">
                    <span>
                      command or system that automatically assigns roles to users
                      based on specific criteria. For example, assigning a
                      &quot;Verified&quot; role to users who complete a
                      verification process.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command44">
                <div className="commands-container47">
                  <h2 className="commands-text292">
                    <span>Twitch Stream Notifications</span>
                    <br></br>
                  </h2>
                  <span className="commands-text295">
                    <span>
                      allows users to subscribe to notifications for a specific
                      Twitch streamer.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command45">
                <div className="commands-container48">
                  <h2 className="commands-text298">
                    <span>Instagram Integration</span>
                    <br></br>
                  </h2>
                  <span className="commands-text301">
                    <span>
                      fetches and displays the latest posts or stories from a
                      specified Instagram account.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command46">
                <div className="commands-container49">
                  <h2 className="commands-text304">
                    <span>Channel Management</span>
                    <br></br>
                  </h2>
                  <span className="commands-text307">
                    <span>Delete - Delete a specific channel</span>
                    <br></br>
                    <span>Add - Create a new channel for your server</span>
                    <br></br>
                    <span>Name - Change the name of a specific channel</span>
                    <br></br>
                    <span>
                      Slowmode - Enable slowmode for a specific channel
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="commands-text315">(</span>
                    <span className="commands-text316">Text Only)</span>
                    <br></br>
                    <span>
                      Max Users - Change the amount of users that can join a
                      channel
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="commands-text319">(Voice Only)</span>
                    <br></br>
                    <span>Lockdown - Shutdown messaging access to a channel</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command47">
                <div className="commands-container50">
                  <h2 className="commands-text323">
                    <span>Logging System</span>
                    <br></br>
                  </h2>
                  <span className="commands-text326">
                    <span>Leave VC</span>
                    <br className="commands-text328"></br>
                    <span>Join VC</span>
                    <br></br>
                    <span>Update PFP</span>
                    <br></br>
                    <span>    • Before and After</span>
                    <br></br>
                    <span>Left Server</span>
                    <br></br>
                    <span>Joined Server</span>
                    <br></br>
                    <span>
                      • Account Age (Shows warning if the account was made
                      within 1 week of current date)
                    </span>
                    <br></br>
                    <span>Role Creation</span>
                    <br></br>
                    <span>Role Deletion</span>
                    <br></br>
                    <span>Updated Member Roles</span>
                    <br></br>
                    <span>Updated Role Permissions/Name</span>
                    <br></br>
                    <span>Update Channel</span>
                    <br></br>
                    <span>Create Channel</span>
                    <br></br>
                    <span>Remove Channel</span>
                    <br></br>
                    <span>Kick User</span>
                    <br></br>
                    <span>Ban User</span>
                    <br></br>
                    <span>Update Server</span>
                    <br></br>
                    <span>Update Channel</span>
                    <br></br>
                    <span>Message Delete</span>
                    <br></br>
                    <span>Message Edit</span>
                    <br></br>
                    <span>Mass Mention</span>
                    <br></br>
                    <span>@everyone Mention Detection</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="commands-command48">
                <div className="commands-container51">
                  <h2 className="commands-text371">
                    <span>Name</span>
                    <br></br>
                  </h2>
                  <span className="commands-text374">
                    <span>Description</span>
                    <br></br>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <span className="commands-text377">
            <span>More commands to come!</span>
            <br></br>
          </span>
        </div>
        <FooterContainer rootClassName="footer-container-root-class-name5"></FooterContainer>
      </div>
    </html>
  )
}

export default Commands
